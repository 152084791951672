import React, { Component } from 'react';
import yonmieWhat from '../assets/yonmie_what.png'
import './TOS.css'
import Transitions from '../../Transitions/Transitions';

export default class TOS extends Component {
	render() {
		return (
			<Transitions>
				<div className="main">
					<div className='inner-tos'>
						<div className='tos'>
							<h3>TERMS OF SERVICE</h3>
							<div className='center'>
								<div class="m-b-25">
									<img src={yonmieWhat} alt='pfp' className='image_yonmie'></img>
								</div>
							</div>
							<br></br>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<div className='rules'>
									<span class='tos_lines'>✦ I have the right to deny your commission for any reason</span>
									<span class='tos_lines'>✦ Please let me know if you would like your commission to not be streamed</span>
									<span class='tos_lines'>✦ If I cannot finish your commission, I will give you a refund</span>
									<span class='tos_lines'>✦ No rushing</span>
									<span class='tos_lines'>✦ Rules are subject to change</span>
									<br></br>
									<span class='tos_lines'>✦ I accept PayPal</span>
									<span class='tos_lines'>✦ Before I start on your commission, I will request 50% of the payment</span>
									<span class='tos_lines'>✦ After you accept the sketch, I will charge for any changes</span>
									<br></br>
									<span class='tos_lines'>✦
										Contact me through twitter <a href='https://twitter.com/yonmie_'>@yonmie</a> or email yonnie.en@gmail.com
									</span>
									<span class='tos_lines'>✦ feel free to ask me any questions</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transitions>
		)
	}
}
