import Transitions from '../../Transitions/Transitions'
import './References.css'
import sketchRef from '../assets/sketch_ref.jpg'
import yonmieModel from '../assets/yonmie_model.png'

export default function References() {
	return (
		<>
			<Transitions>
				<div className="main">
					<div className='inner-references'>
						<h2 className='references-title'>References</h2>
						<div className='card'>
							<div className='references'>
								<div className='ref-row'>
									<img src={sketchRef} alt='ref' />
								</div>
								<div className='ref-row'>
									<img src='https://f2.toyhou.se/file/f2-toyhou-se/images/74077515_7OopAwek0WY8s8r.jpg?1706936421' alt='ref' />
								</div>
							</div>
							<div className='please-read-row'>
								<div className='ref-row'>
									<img src={yonmieModel} alt='yonmie-model' />
								</div>
								<div className='ref-row'>
									<div className='please-read-col'>
										<div className='please-read'>
											PLEASE READ
										</div>
										<div className='contents'>
											Yon's bangs are <b>separated</b>. The small red strands on the middle of her head are
											<b> separated</b> from the rest of her bangs.
										</div>
										<div className='contents'>
											The original reference does <b>NOT</b> have bandage on her left horn, please include the bandage.
										</div>
										<div className='contents'>
											Yon has shark teeth. Please include them when drawing her mouth open.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transitions>
		</>
	)
}