import { Divider } from '@mui/material';
import React, { Component } from 'react';
import './Portfolio.css'

import gordPort from '../assets/gord_port.jpg'
import gord2Port from '../assets/gord2_port.jpg'
import gord3Port from '../assets/gord3_port.jpg'
import yonPort from '../assets/yon_port.jpg'
import mystiPort from '../assets/mysti_port.jpg'
import Transitions from '../../Transitions/Transitions';


export default class Portfolio extends Component {
	render() {
		return (
			<Transitions>
				<div className="main">
					<div className='inner-portfolio'>
						<p className='example-art-text'>
							<span>More examples of my art of Twitter <a href='https://twitter.com/yonmie_'>@yonmie</a></span>
						</p>
						<div className='header-top'></div>
						<div className='gallery'>
							<div>
								<span className='frame'></span><img src={gordPort} alt='gordPort'></img>
							</div>
							<div>
								<span className='frame'><img src={gord2Port} alt='gord2Port'></img></span>
							</div>
							<div>
								<span className='frame'><img src={gord3Port} alt='gord3Port'></img></span>
							</div>
							<div>
								<span className='frame'><img src={yonPort} alt='yonPort'></img></span>
							</div>
							<div>
								<span className='frame'><img src={mystiPort} alt='mystiPort'></img></span>
							</div>
						</div>
					</div>
				</div>
			</Transitions>
		)
	}
}
