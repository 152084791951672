import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import './App.css';

import MainHeader from './components/Header/MainHeader';
import Home from './components/pages/Home/Home'
import Commission from "./components/pages/Commmission/Commission";
import Portfolio from "./components/pages/Portfolio/Portfolio";
import TOS from "./components/pages/TOS/TOS";
import About from './components/pages/About/About'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faTwitch, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import References from "./components/pages/References/References";
import { Divider } from "@mui/material";
import { AnimatePresence } from "framer-motion";

// icons
library.add(fas, faTwitter, faTwitch, faInstagram, faYoutube, faTiktok)

const Animated = () => {
  const location = useLocation();
  return (
      <AnimatePresence>
          <Routes
              location={location}
              key={location.pathname}
          >
            <Route path="/about" element={<About />} />
            <Route path="/references" element={<References />} />
            <Route path="/" element={<Home />} />
            <Route path="/commissions" element={<Commission />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/tos" element={<TOS />} />
          </Routes>
      </AnimatePresence>
  );
};


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MainHeader />
        <Animated />
      </BrowserRouter>
      <div className='footer'>
        <Divider></Divider>
        <div className="credits">
          <footer>
            © {(new Date().getFullYear())} Website created by&nbsp;
            <a className="mysti" href='https://toyhou.se/Mysti' target='_blank' data-toggle="tooltip" rel='noreferrer'>
              Mysti
            </a>
          </footer>
          <div className="socials">
            <div className="yonmie">
              YONMIE
            </div>
            <ul className="icons" id='icons'>
              <li><a href='https://www.twitch.tv/yonmie'><FontAwesomeIcon icon="fab fa-twitch" /></a></li>
              <li><a href='https://x.com/yonmie_'><FontAwesomeIcon icon="fab fa-twitter" /></a></li>
              <li><a href='https://www.tiktok.com/@yonmievt'><FontAwesomeIcon icon="fab fa-tiktok" /></a></li>
              <li><a href='https://www.youtube.com/@yonmie'><FontAwesomeIcon icon="fab fa-youtube" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="oni-peek"></div> */}
    </div>
  );
}

export default App;
