import './About.css'
import Transitions from '../../Transitions/Transitions';
import yonPfp from '../assets/yonmie-forget-me-not.png'

export default function About() {
	return (
		<>
			<Transitions>
				<div className="main">
					<div className='inner-about'>
						<div className='card'>
							<div className='about'>
								<div className='about-col'>
									<img className='pfp' src={yonPfp} alt='pfp' />
								</div>
								<div className='about-col'>
									<h2 className='about-me title'>ABOUT ME</h2>
									<div className='about-me contents'>
										I'm a variety vtuber affiliated on Twitch. My content focuses on variety games, learning
										various topics, and improving various skills such as art, singing, and editing.
									</div>
									<div className='about-me contents'>
										My hobbies include creating character art and book reading on stream.
									</div>
									<div className='about-me contents'>
										Yon is an endearing oni who aspires to be your idol one day. She hopes to inspire you to
										keep trying even when everything else is pushing you down.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transitions>
		</>
	)
}