import { motion } from "framer-motion";
import './Transition.css'

const animationConfiguration = {
	initial: { opacity: 0 },
	animate: { opacity: 1 }
};
const Transitions = ({ children }) => {
	return (
		<motion.div
			className="motion"
			variants={animationConfiguration}
			initial="initial"
			animate="animate"
			transition={{ duration: 1 }}
		>
			{children}
		</motion.div>
	);
};
export default Transitions;