import { Navbar, Container, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import './MainHeader.css'
import logo from '../pages/assets/Yonmie_Logo.png'
import { Link } from "react-router-dom";
import { useState } from "react";

const MainHeader = () => {

  return (
    <Navbar expand="lg" className="main-header">
        <Navbar.Brand><Link to='/'><img className='logo' src={logo} alt='logo' /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav justify-content-center border-0" id="button-list">
            <Nav.Link className="button"><Link to='about'>About</Link></Nav.Link>
            <Nav.Link className="button"><Link to='references'>References</Link></Nav.Link>
            <Nav.Link className="button" href="https://yonmie-shop.fourthwall.com"><a href="https://yonmie-shop.fourthwall.com">Shop</a></Nav.Link>
            <Nav.Link className="button"><Link to='commissions'>Commissions</Link></Nav.Link>
            <Nav.Link className="button"><Link to='tos'>TOS</Link></Nav.Link>
            <Nav.Link className="button"><Link to='portfolio'>Portfolio</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
};

export default MainHeader;