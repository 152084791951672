import './Home.css'
import { Component } from 'react';
import image from '../assets/Yon.png'
import Transitions from '../../Transitions/Transitions';

export default class Home extends Component {
  render() {
    return (
      <Transitions>
        <div className="main-page">
          <div className='inner'>
            <div className='wallpaper'>
              <img src={image} alt='wallpaper' className='image'></img>
            </div>
          </div>
        </div>
      </Transitions>
    )
  }
};