import './Commission.css'
import { Component } from 'react'
import gord from '../assets/gord.jpg'
import kahyo from '../assets/kahyo.jpg'
import doki from '../assets/doki.jpg'
import kahyoEmote from '../assets/kahyo_emote.jpg'
import razorEmote from '../assets/razor_emote.png'
import unknownEmote from '../assets/unknown_emote.png'
import cylEmote from '../assets/cyl_emote.png'
import corEmote from '../assets/cor_emote.png'
import Transitions from '../../Transitions/Transitions';

export default class Commission extends Component {
	render() {
		return (
			<Transitions>
				<div className="main">
					<div className='inner-comm'>
						<div className='header-top'></div>
						<div className='sketch-commissions'>
							<h3>SKETCH COMMISSIONS <span className='closed'>CLOSED</span></h3>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<ul className='col' id='image-list'>
									<li><span><img className='commImg' src={gord} alt='gord'></img></span></li>
									<li><span><img className='commImg' src={kahyo} alt='kana'></img></span></li>
									<li><span><img className='commImg' src={doki} alt='doki'></img></span></li>
								</ul>
							</div>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<h1 id='prices'>
									<span className='p'>
										<span>✦ Bust Up <span style={{ color: 'black' }}>$5</span></span>
										<br></br>
										<span>✦ Thigh Up <span style={{ color: 'black' }}>$5</span></span>
										<br></br>
										<span>✦ Chibi <span style={{ color: 'black' }}>$5</span></span>
									</span>
								</h1>
							</div>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<ul>
									<span className='p'>
										<li><sub>• You can choose which background color you would like</sub></li>
										<li><sub>• Only one character</sub></li>
									</span>
								</ul>
							</div>
						</div>
						<div className='header' style={{ marginTop: '1rem' }}></div>
						<div className='emote-commissions'>
							<h3>EMOTE COMMISSIONS <span className='closed'>CLOSED</span></h3>
							<div className='nav border-0 pt-1 pb-1' style={{ display: 'flex', flexWrap: 'wrap' }}>
								<ul className='col' id='image-list'>
									<li><span><img className='commImg' src={kahyoEmote} alt='kahyoEmote'></img></span></li>
									<li><span><img className='commImg' src={razorEmote} alt='razorEmote'></img></span></li>
									<li><span><img className='commImg' src={cylEmote} alt='cylEmote'></img></span></li>
									<li><span><img className='commImg' src={unknownEmote} alt='unknownEmote'></img></span></li>
									<li><span><img className='commImg' src={corEmote} alt='corEmote'></img></span></li>
								</ul>
							</div>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<h1 id='prices'>
									<span className='p'>
										<span>✦ <span style={{ color: 'black' }}>$10 / each</span></span>
										<br></br>
										<span>✦ Package<span style={{ color: 'black' }}> $40 / 5 emotes</span></span>
									</span>
								</h1>
							</div>
							<div className='nav justify-content-beginning border-0 pt-1 pb-1'>
								<ul>
									<span className='p'>
										<li><sub>• Commercial rights included in pricing</sub></li>
									</span>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</Transitions>
		)
	}
}